import { BLOG_TOKENS } from 'constants/Languages'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'

import AllPosts from 'components/Common/Blog/AllPosts'
import Blog from 'components/Common/Blog/Blog'
import layoutContext from 'components/layoutContext'
import SEO from 'components/seo'

const BlogCategory = ({ pageContext, data }) => {
  const { allMarkdownRemark } = data
  const { group } = allMarkdownRemark
  const { tag, lang, link } = pageContext
  useContext(layoutContext).setCult(lang)
  let posts = []
  let newPosts = []
  let popularPosts = []
  for (let post of group) {
    let { edges, fieldValue } = post
    if (tag[lang] === fieldValue) {
      posts.push(edges)
    }
  }

  posts = posts[0] ? posts[0] : []

  for (let newPost of posts) {
    newPosts.push(newPost)
    if (newPosts.length === 3) {
      break
    }
  }

  for (let popularPost of posts) {
    let {
      node: {
        frontmatter: { tags }
      }
    } = popularPost
    if (tags.includes('popular')) {
      popularPosts.push(popularPost)
    }
    if (popularPosts.length === 3) {
      break
    }
  }

  return (
    <>
      <SEO title={tag[lang]} path={link} type='tag' lang={lang} />
      <div className='medium-container'>
        <h1>{tag[lang]}</h1>
        <h2 className='posts-title'>{BLOG_TOKENS[lang].NEW}</h2>
        <Blog posts={newPosts} />
        <h2 className='posts-title'>{BLOG_TOKENS[lang].POPULAR}</h2>
        <Blog posts={popularPosts} />
        <h2 className='posts-title'>{BLOG_TOKENS[lang].ALL}</h2>
        <AllPosts posts={posts} />
      </div>
    </>
  )
}

BlogCategory.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
    lang: PropTypes.string,
    link: PropTypes.string
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired
        }).isRequired
      )
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired
      })
    })
  })
}

export default BlogCategory

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/posts/" } }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        edges {
          node {
            excerpt
            frontmatter {
              description
              path
              tags
              title
              image {
                childImageSharp {
                  resize(width: 250, height: 250) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
