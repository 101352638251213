import PropTypes from 'prop-types'
import React from 'react'

import './Pagination.scss'

const Pagination = ({ page, setPage, paginationPages }) => (
  <div className='pagination'>
    {page !== 1 && (
      <div className='pagination-arrow' onClick={() => setPage(page - 1)}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
        >
          <path
            fill='#293c5e'
            d='M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z'
          />
        </svg>{' '}
      </div>
    )}
    {paginationPages.map(page => (
      <div className='pagination-page' onClick={() => setPage(page)} key={page}>
        {page}
      </div>
    ))}
    {page !== paginationPages.length && (
      <div onClick={() => setPage(page + 1)} className='pagination-arrow'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
        >
          <path
            fill='#293c5e'
            d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z'
          />
        </svg>{' '}
      </div>
    )}
  </div>
)

export default Pagination

Pagination.propTypes = {
  page: PropTypes.number,
  setPage: PropTypes.number,
  paginationPages: PropTypes.array
}
