import PropTypes from 'prop-types'
import React, { useState } from 'react'

import Pagination from './Pagination'
import Post from './Post'

const AllPosts = ({ posts }) => {
  const [page, setPage] = useState(1)
  const paginationPages = []

  const pagesCount = posts.length / 3

  let postsInPage = posts.reduce((pages, post, index) => {
    if (index >= (page - 1) * 3 && index < page * 3) {
      pages.push(post)
    }
    return pages
  }, [])

  for (let i = 1; i < pagesCount + 1; i++) {
    paginationPages.push(i)
  }

  return (
    <>
      <div className='all-posts'>
        <div className='blog'>
          {postsInPage.map(
            ({
              node: {
                frontmatter: {
                  path,
                  title,
                  description,
                  image: {
                    childImageSharp: {
                      resize: { src }
                    }
                  }
                }
              }
            }) => (
              <Post
                key={path}
                path={path}
                title={title}
                src={src}
                excerpt={description}
              />
            )
          )}
        </div>
        {paginationPages.length > 1 && (
          <Pagination
            page={page}
            setPage={setPage}
            paginationPages={paginationPages}
          />
        )}
      </div>
    </>
  )
}

export default AllPosts

AllPosts.propTypes = {
  posts: PropTypes.array
}
